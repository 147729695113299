// ** Logo
import logo from 'assets/images/logo-cut.png';

const SpinnerComponent = () => {
  return (
    <div>
      <img
        className="fallback-logo"
        src={logo}
        alt="logo"
        style={{
          maxWidth: '100%',
          maxHeight: '100%',
          bottom: 0,
          left: 0,
          margin: 'auto',
          overflow: 'auto',
          position: 'fixed',
          right: 0,
          top: 0,
          OObjectFit: 'contain',
          objectFit: 'contain',
        }}
      />
      <div className="loading">
        <div className="effect-1 effects"></div>
        <div className="effect-2 effects"></div>
        <div className="effect-3 effects"></div>
      </div>
    </div>
  );
};

export default SpinnerComponent;
