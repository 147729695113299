import React, { lazy, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import Spinner from 'DS/components/DsLoader/FullScreenSpinner';

// Soft UI Context Provider

import { TargetUserControllerProvider } from 'contexts/targetUser';

import { AuthProvider } from 'contexts/auth/auth.provider';

Date.prototype.yyyymmdd = function () {
  const mm = this.getMonth() + 1; // getMonth() is zero-based
  const dd = this.getDate();

  return [this.getFullYear(), (mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd].join('-');
};

// ** Lazy load app
const LazyApp = lazy(() => import('./App'));

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <Suspense fallback={<Spinner />}>
      <TargetUserControllerProvider>
        <AuthProvider>
          <LazyApp />
        </AuthProvider>
      </TargetUserControllerProvider>
    </Suspense>
  </BrowserRouter>
);
